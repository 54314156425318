import axios from "axios";
import {EventDispatcher} from "@/utils/event-dispatcher";
import UserModule from "@/components/modules/UserModule.vue";
import {ApiGetLoginUserInfo} from "@/request-apis/user/login-user-info";

export enum UserEvent {
    ON_LOGIN_STATE_REFRESH = "ON_LOGIN_STATE_REFRESH",
}

export type ILoginUserInfo = IUserInfo & {
    targetOpenId:string | null;
}

export type IUserInfo = {
    uid:number,
    username:string,
    head:string,
    email:string,
    group:number,
    createTime:Date
}

export default class UserManager extends EventDispatcher {
    private static _inst:UserManager | null = null;
    public static getInstance() {
        if(this._inst == null) {
            this._inst = new UserManager();
        }
        return this._inst;
    }

    private _isLoading:boolean = false;
    private _loginUserInfo:ILoginUserInfo | null = null;
    private _userModuleComp?:UserModule = undefined;

    private constructor() {
        super();
        this.doLoadLogin();
    }

    public get isLoading() {
        return this._isLoading;
    }

    public getLoginUserInfo() {
        return this._loginUserInfo;
    }

    public getIsBindQQ() {
        return !!(this._loginUserInfo && this._loginUserInfo.targetOpenId);
    }

    public popupAuthQQ() {
        this._userModuleComp?.$emit('popupAuthQQ');
    }

    public popupLogin(mode:'popup' | 'jump', callbackUrl:string) {
        if(mode == 'popup') {
            let width = 1000;
            let height = 500;
            let left = (screen.width/2)-(width /2);
            let top = (screen.height/2)-(height /2);
            let win = window.open('https://qqlogin.magecorn.com/login.php?st=current-page',"qqLoginWindow",`height=${height}, width=${width}, left=${left}, top=${top} toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no`)
            if(win) {
                let checker = setInterval(() => {
                    if(win) {
                        if(win.closed) {
                            setTimeout(() => {
                                UserManager.getInstance().doLoadLogin();
                            },500);
                            clearInterval(checker);
                        }
                    }
                },100)
            }
        } else if(mode == 'jump') {
            window.location.href = `https://qqlogin.magecorn.com/login.php?st=${callbackUrl}`;
        }
    }

    public doLoadLogin() {
        this._isLoading = true;
        new ApiGetLoginUserInfo().run().then(res => {
            this._isLoading = false;
            this._loginUserInfo = res.info;
            this.emit(UserEvent.ON_LOGIN_STATE_REFRESH);
        }).catch(e => {
            this._isLoading = false;
            this._loginUserInfo = null;
            this.emit(UserEvent.ON_LOGIN_STATE_REFRESH);
        })
    }
}