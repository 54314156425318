



























import {Vue} from "vue-property-decorator";
import UserManager, {IUserInfo, UserEvent} from "@/managers/user-manager";
import Component from "vue-class-component";
import {ApiUserLogout} from "@/request-apis/user/logout";

@Component
export default class UserHead extends Vue {
    private headSrc:string = "";
    private userIsReady:boolean = false;
    private loginUserInfo:IUserInfo | null = null;
    private showAccountAuth:boolean = false;
    private badgeCountHead:number = 0;
    private badgeCountAccAuth:number = 0;

    public mounted() {
        UserManager.getInstance().on(UserEvent.ON_LOGIN_STATE_REFRESH, this, this.onLoginStateRefresh);
        if(!UserManager.getInstance().isLoading) {
            this.onLoginStateRefresh();
        }
    }

    private onLoginStateRefresh() {
        this.badgeCountAccAuth = 0;
        let userinfo = UserManager.getInstance().getLoginUserInfo();
        this.loginUserInfo = userinfo;
        if(userinfo) {
            this.headSrc = userinfo.head;
        } else {
            this.headSrc = "";
        }
        let isBindQQ = UserManager.getInstance().getIsBindQQ();
        this.showAccountAuth = !isBindQQ;
        if(!isBindQQ) {
            this.badgeCountAccAuth += 1;
        }
        // 计算头像小红点
        this.badgeCountHead = this.badgeCountAccAuth;
        this.userIsReady = true;
    }

    private onLoginClick() {
        if(this.$store.state.device === 'pc') {
            UserManager.getInstance().popupLogin('popup', window.location.href);
        } else if(this.$store.state.device === 'mobile') {
            UserManager.getInstance().popupLogin('jump', window.location.href);
        }
    }

    private onBindQQClick() {
        UserManager.getInstance().popupAuthQQ();
    }

    private onLogoutClick() {
        let self:any = this;
        self.$confirm({
            title: '退出登录',
            content: '您是否确定退出当前账号？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                new ApiUserLogout().run().then((data) => {
                    if(data.code == 0) {
                        this.userIsReady = false;
                        UserManager.getInstance().doLoadLogin();
                    }
                })
            },
            onCancel() {

            },
        })
    }
}
