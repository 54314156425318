import NetManager from "@/managers/net/net-manager";

export enum ApiMethod {
    GET = "GET",
    POST = "POST",
}

type ApiSuccessResult = {
    code: 0,
    msg: string,
}

export type ApiError = {
    code: number,
    msg: string,
}

export default abstract class Api<Resolve> {
    public path:string = "";
    public method:ApiMethod = ApiMethod.GET;
    protected readonly _query?:{[key:string]:string | undefined};
    protected readonly _data?:{[key:string]:string};
    protected readonly _headers?:{[key:string]:string};

    protected constructor(query?:{[key:string]:string | undefined} | Function, post?:{[key:string]:string} | Function, headers?:{[key:string]:string} | Function) {
        if(query instanceof Function) {
            this._query = query();
        } else {
            this._query = query;
        }
        if(post instanceof Function) {
            this._data = post();
        } else {
            this._data = post;
        }
        if(headers instanceof Function) {
            this._headers = headers();
        } else {
            this._headers = headers;
        }
    }

    /**
     * @throws ApiError
     */
    public async run():Promise<Resolve & ApiSuccessResult> {
        let response = await NetManager.send(this.path, this.method, this._query, this._data, this._headers);
        if(response.data && response.data.code == 0) {
            return response.data;
        } else {
            throw response.data;
        }
    }
}

export abstract class DofApi<Resolve> extends Api<any> {
    /**
     * @throws ApiError
     */
    public async run():Promise<Resolve & ApiSuccessResult> {
        let response = await NetManager.send(this.path, this.method, this._query, this._data, this._headers);
        if(response.data && response.data.code == "success") {
            return response.data;
        } else {
            throw response.data;
        }
    }
}
